import React, { Component } from 'react'
import { Link } from 'gatsby'

import SEO from '../components/seo'


class NotFoundPage extends Component {

  render() {
    return (
      <section className="g-bg-gray-dark-v1 g-color-white g-height-100x g-min-height-100vh g-flex-centered g-pa-15">
        <SEO title="404: Not found" />
        <div className="text-center g-flex-centered-item g-position-rel">
          <div className="g-font-size-180 g-font-size-240--sm g-font-size-420--lg g-line-height-1 g-font-weight-200 g-color-gray-dark-v2">404</div>

          <div className="g-absolute-centered">
            <h1 className="g-font-weight-200 g-mb-20">Page not found</h1>
            <p className="g-color-white-opacity-0_6 g-font-size-18">
              Oops! Looks like you followed a bad link.
            </p>
            <p className="g-color-white-opacity-0_6 g-font-size-18 mb-0">
              Here is a link to the <Link to={'/'}>Home</Link>
            </p>
          </div>
        </div>
      </section>
    )
  }
}

export default NotFoundPage
